<template>
  <div class="risk-factors-definitions">
    <div>
      <p>
        <strong>{{ stressIndex.title }}</strong
        >{{ stressIndex.first }}
      </p>
      <p>{{ stressIndex.second }}</p>
      <p>{{ stressIndex.third }}</p>
    </div>
    <div>
      <p>
        <strong>{{ reliability.title }}</strong
        >{{ reliability.first }}
      </p>
      <div>
        <ul v-for="indicator in reliability.indicators" :key="indicator">
          <li>
            <p>
              <strong>{{ indicator }}:</strong>{{ reliability[indicator] }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <p>
        <strong>{{ remainingUsefulLife.title }}</strong
        >{{ remainingUsefulLife.first }}
      </p>
      <p>{{ remainingUsefulLife.second }}</p>
    </div>
  </div>
</template>

<script>
import { riskFactorsDescriptions } from "@/helpers/variables";

export default {
  name: "RiskFactorsDefinitions",
  data() {
    return {
      riskFactorsDescriptions,
    };
  },
  computed: {
    stressIndex() {
      return this.riskFactorsDescriptions.find(
        (item) => item.title === "Stress index:",
      );
    },
    reliability() {
      return this.riskFactorsDescriptions.find(
        (item) => item.title === "Reliability index:",
      );
    },
    remainingUsefulLife() {
      return this.riskFactorsDescriptions.find(
        (item) => item.title === "Remaining useful life (RUL):",
      );
    },
  },
};
</script>
