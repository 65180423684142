<template>
  <v-alert class="mx-8" type="error">
    <span v-if="error.value">{{ error.value }}</span>
    <template v-slot:close>
      <v-icon @click="closeAlert(error)">mdi-close</v-icon>
    </template>
  </v-alert>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ErrorAlert",
  props: {
    error: {
      type: Object,
      require: true,
      default: () => [],
    },
    index: {
      type: Number,
      require: true,
      default: null,
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.error.errors,
    }),
  },
  methods: {
    ...mapActions({
      dismissError: "error/dismissError",
    }),
    closeAlert() {
      this.dismissError(this.index);
    },
  },
};
</script>
