<template>
  <div class="menu-container">
    <div
      v-if="unreadUnarchivedNotificationsCount"
      class="pointer"
      @click="handleClickNotificationsIcon"
    >
      <div
        class="unread-indicator"
        v-if="unreadUnarchivedNotificationsCount < 20"
      >
        {{ unreadUnarchivedNotificationsCount }}
      </div>
      <div class="unread-indicator" v-else>20+</div>
    </div>
    <v-menu
      v-model="showNotifications"
      left
      z-index="1050"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          size="24px"
          class="mb-1 pointer icon-hover"
          data-cy="notification-menu-toggle"
          @click="handleClickNotificationsIcon"
          >mdi-bell-outline</v-icon
        >
      </template>

      <div class="px-3 notifications-container">
        <v-list>
          <div class="d-flex justify-space-between align-center ma-3">
            <div class="text-subtitle-1 font-weight-bold mr-2">
              Notifications
            </div>
            <v-text-field
              v-model="filter"
              label="Search..."
              solo
              dense
              hide-details
              clearable
              data-ga="notifications_dropdown_search"
              append-icon="mdi-magnify"
              style="max-width: 50%"
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="pic-container" data-cy="notification-menu">
            <div
              class="d-flex justify-center"
              v-if="dropdownLoading && notifications.length === 0"
            >
              <v-progress-circular
                :size="45"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <Notifications
              :dropdown="true"
              :notifications="filteredResults"
              @toggleShowNotifications="handleToggleShowNotifications"
            />
            <div
              v-if="filteredResults.length === 0 && notifications.length !== 0"
            >
              No search results.
            </div>
            <div v-if="notifications.length === 0 && !dropdownLoading">
              Congrats, you're all caught up!
            </div>
          </div>
          <div class="d-flex justify-center ma-4">
            <v-btn color="primary" outlined @click="goToNotificationsPage"
              >View all</v-btn
            >
          </div>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Notifications from "@/components/Notifications";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "NotificationMenu",
  components: { Notifications },
  data() {
    return {
      filter: "",
      archiveIcon: "mdi-archive-plus-outline",
      archiveTooltip: "Archive notification",
      archiveIconVisible: false,
      notificationId: null,
      showNotifications: false,
    };
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.dropdownNotifications,
      dropdownLoading: (state) => state.notifications.dropdownLoading,
      archiveSwitch: (state) => state.notifications.archiveSwitchValue,
      unreadUnarchivedNotificationsCount: (state) =>
        state.notifications.unreadUnarchivedNotificationsCount,
      clickCount: (state) => state.app.clickCount,
    }),
    filteredResults() {
      if (this.filter !== "") {
        return this.notifications.filter(
          (x) =>
            x.actor_user_full_name
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            x.message.toLowerCase().includes(this.filter.toLowerCase()) ||
            x.ts.includes(this.filter),
        );
      }

      return this.notifications;
    },
  },
  methods: {
    ...mapActions({
      getUserNotifications: "notifications/getUserNotifications",
      incrementClickCount: "app/incrementClickCount",
    }),
    async refreshUserNotifications() {
      await this.getUserNotifications({
        limit: 20,
        offset: 0,
        get_total_count: false,
        get_unread_unarchived_count: true,
        container: "dropdown",
      });
    },
    handleToggleShowNotifications(value) {
      this.showNotifications = value;
    },
    goToNotificationsPage() {
      const routeName = this.$route.name;
      if (this.clickCount < clickCountLimit) {
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: "button_go_to_notifications_page",
          value: this.clickCount,
        });
        this.incrementClickCount();
      }
      if (routeName !== "Notifications") {
        this.$router.push({
          name: "Notifications",
        });
      }
      this.showNotifications = false;
    },
    handleClickNotificationsIcon(event) {
      // Data sent to Google Analytics
      if (!this.showNotifications && this.clickCount < clickCountLimit) {
        this.incrementClickCount();
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: "icon_open_notifications_dropdown",
          value: this.clickCount,
        });
      }
      // If unread indicator dot clicked
      if (event.target.className.includes("indicator")) {
        this.showNotifications = true;
      }
    },
  },
  async mounted() {
    await this.refreshUserNotifications();
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";
.pointer:hover {
  cursor: pointer;
}

.unread-indicator {
  position: absolute;
  top: 5%;
  left: 45%;
  width: 14px;
  line-height: 14px;
  border-radius: 50%;
  text-align: center;
  font-size: 7px;
  padding-left: 0.5px;
  color: white;
  background-color: $accentBlue;
  z-index: 10;
}

.message-wrapper {
  position: relative;
  line-height: 1.5em;
  // With coreui enabled, styling is added to body, interacting with following rules
  // text-align: left; TODO: Enable once coreui is removed
  //  padding-bottom: 0.75rem; TODO: Enable once coreui is removed
  cursor: pointer;
}

.message-wrapper:hover {
  background-color: var(--v-hover-base);
}

.archive-icon {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-92%, -50%);
  border-radius: 10px;
  background-color: var(--v-secondary-base);
  z-index: 1050;
}

.pic-container {
  overflow: hidden;
  height: 400px; /* Would be neater if auto, but scrolling will not work */
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.notification-timestamp {
  padding-bottom: 1px;
}

.menu-container {
  position: relative;
}

.notifications-container {
  position: relative;
  background-color: var(--v-secondary-base);
  width: 480px;
  z-index: 1050;
}
// TODO: apply to caught up text beneath notifications component
.caught-up-text {
  text-align: center;
  font-size: 0.875em;
  margin-top: 3rem;
}
</style>
