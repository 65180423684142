<template>
  <div class="sidebar-list-item">
    <v-list-group
      v-if="item.sites !== undefined || item.turbines !== undefined"
      v-model="item.active"
      no-action
      :sub-group="isSubGroup"
      :class="{ 'right-icon': isSubGroup }"
      class="org-list-item"
    >
      <template v-slot:activator>
        <v-list-item-content
          class="v-list-item__border"
          :class="{
            'v-list-item--active item-active':
              ($route.path.includes('org') && item.id == $route.params.orgId) ||
              ($route.path.includes('site') && item.id == $route.params.siteId),
          }"
          :style="{ borderLeft: borderColor }"
          :ref="item.id"
        >
          <v-list-item-title link router @click.stop="goTo(item)">{{
            item.name
          }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <template>
        <div
          v-for="subItem in item.sites ? item.sites : item.turbines"
          :key="subItem.id"
        >
          <sidebar-list-item
            :item="subItem"
            :is-sub-group="true"
            :class="{ 'site-list-item': item.sites }"
            :turbine-id="turbineId"
          >
          </sidebar-list-item>
        </div>
      </template>
    </v-list-group>
    <v-list-item
      id="sidebar-turbine"
      class="turbine-list-item"
      v-else
      link
      router
      @click.stop="goTo(item)"
    >
      <v-list-item-content
        :ref="item.id"
        :class="{
          'v-list-item--active item-active':
            (item.id == $route.params.turbineId &&
              !$route.path.includes('issue')) ||
            (item.id == turbineId && $route.path.includes('issue')),
        }"
        class="v-list-item__turbine v-list-item__border"
        :style="{ borderLeft: borderColor }"
      >
        <v-list-item-title>{{
          item.fullName ? item.fullName : `Turbine ${item.shortname}`
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import SidebarListItem from "@/components/SidebarListItem";
import { mapState, mapActions } from "vuex";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "SidebarListItem",
  components: {
    SidebarListItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isSubGroup: {
      type: Boolean,
      required: true,
      default: false,
    },
    turbineId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
    }),
    routeWatcher() {
      return {
        route: this.$route,
      };
    },
    // Used to track what item we are on
    currentId() {
      if (
        this.$route.params.issueId &&
        this.item &&
        this.$route.path.includes("issue")
      ) {
        if (this.item.id == this.turbineId) {
          return this.item.id;
        }
      }
      if (this.$route.params.turbineId && !this.$route.path.includes("issue")) {
        return this.$route.params.turbineId;
      }
      if (this.$route.params.siteId) {
        return this.$route.params.siteId;
      }
      if (this.$route.params.orgId) {
        return this.$route.params.orgId;
      }
      return null;
    },
    borderColor() {
      return this.$vuetify.theme.isDark
        ? "1px solid rgba(255, 255, 255, 0.7)"
        : "1px solid #000";
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
    }),
    goTo(item) {
      let name = `org_${item.name.replaceAll(" ", "_")}`;
      if (item.site_name) {
        name = `site_${item.site_name.replaceAll(" ", "_")}`;
      } else if (item.turbine_name) {
        name = `turbine_${item.turbine_name.replaceAll(
          " ",
          "_",
        )}_${item.name.replaceAll(" ", "_")}`;
      }
      if (this.clickCount < clickCountLimit) {
        this.incrementClickCount();
        // Data sent to Google Analytics
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: `sidebar_${name.toLowerCase()}`,
          value: this.clickCount,
        });
      }
      const id = item.id;
      if (item.sites) {
        this.$router
          .push({
            name: "OrgDashboard",
            params: { orgId: id },
          })
          .catch(() => {});
      } else if (item.turbines) {
        this.$router
          .push({
            name: "SiteDashboard",
            params: { siteId: id },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "TurbineDashboard",
            params: { turbineId: id },
          })
          .catch(() => {});
      }
    },
    scrollTo() {
      this.$refs[this.currentId].scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    },
  },
  mounted() {
    if (this.$refs[this.currentId]) {
      this.scrollTo();
    }
  },
  watch: {
    routeWatcher: {
      immediate: true,
      deep: true,
      handler() {
        setTimeout(() => {
          if (this.$refs[this.currentId]) {
            this.scrollTo();
          }
        }, 500);
      },
    },
    turbineId: {
      immediate: true,
      deep: true,
      handler() {
        setTimeout(() => {
          if (this.$refs[this.currentId]) {
            this.scrollTo();
          }
        }, 500);
      },
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/_variables";
.sidebar-list-item,
.sidebar {
  .v-list-item {
    padding: 0;
  }
}
.right-icon .v-list-group__header {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.turbine-list-item {
  padding-left: 64px !important;
}
.v-list-item__content {
  padding: 0;
  min-height: 48px;
}
.v-list-item__turbine {
  padding-left: 8px;
}
.v-list-item__icon {
  margin-right: 0px !important;
}
.v-list-item__border {
  padding-left: 16px;
}
.mdi-menu-down {
  transform: rotate(-90deg) !important;
}
.v-icon.mdi.mdi-menu-down.theme--light,
.mdi-collapse-all-outline {
  color: var(--v-black5-base) !important;
}
.mdi-collapse-all-outline {
  transform: scale(0.8);
}
.mdi-collapse-all-outline.v-icon.v-icon::after {
  background-color: transparent;
}
.org-list-item .v-list-item--active .mdi-menu-down {
  transform: rotate(0deg) !important;
}
.site-list-item .v-list-group--active .mdi-menu-down {
  transform: rotate(0deg) !important;
}
.v-list-item__border.item-active {
  border-left: 2px solid var(--v-primary-base) !important;
  padding-left: 15px !important;

  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}
.item-active .mdi-menu-down {
  color: var(--v-primary-base) !important;
}
.v-list-item--link:before {
  background-color: transparent;
}
.v-list-item__title {
  white-space: unset;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 16px;
}
.v-list-item__title {
  color: var(--v-black3-base) !important;
}
.v-list-item__title:hover {
  color: var(--v-primary-base) !important;
  text-decoration: underline;
}
.v-list-item__icon.v-list-group__header__prepend-icon {
  margin: auto;
}
</style>
