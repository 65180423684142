<template>
  <div data-cy="burger-flyout" class="burger" v-if="showBurger">
    <div
      data-cy="burger-overlay"
      class="burger__overlay"
      @click="handleCloseHandler"
    ></div>
    <div
      class="burger__content"
      v-if="burgerData"
      :class="showBurger ? 'slide-in' : 'slide-out'"
    >
      <div class="burger__content__header d-flex justify-content-end mb-5">
        <v-btn
          icon
          class="p-0 burger__content__header__close"
          @click="handleCloseHandler"
        >
          <CIcon name="cil-x" />
        </v-btn>
      </div>
      <h3
        v-if="burgerData.name"
        class="main-title mb-4"
        style="white-space: normal"
      >
        {{ burgerData.name }}
      </h3>
      <p v-if="burgerData.startDate && burgerData.endDate">
        <span data-cy="subscription_start"
          >Valid {{ burgerData.startDate }}</span
        >
        <span data-cy="subscription_end">
          through {{ burgerData.endDate }}.</span
        >
      </p>
      <p v-else-if="burgerData.startDate">
        <span data-cy="subscription_start"
          >Valid from {{ burgerData.startDate }}.</span
        >
      </p>
      <div
        v-if="
          burgerData.diTableDescription1 &&
          burgerData.diTableDescription2 &&
          burgerData.diTableKnowledgeLink
        "
      >
        <p>
          {{ burgerData.diTableDescription1
          }}<span
            ><a
              :href="knowledgeBaseUrl + '/kb-search-results?term=data'"
              class="knowledge-base-link"
              >{{ burgerData.diTableKnowledgeLink }}</a
            ></span
          >
        </p>
        <p>{{ burgerData.diTableDescription2 }}</p>
      </div>
      <div class="glossary-table">
        <vue-markdown
          data-cy="description_in_flyout"
          v-if="description"
          :anchor-attributes="anchorAttrs"
          :breaks="false"
          >{{ description }}</vue-markdown
        >
      </div>
      <RiskFactorsDefinitions v-if="burgerData.isRiskFactors" />
      <p v-if="burgerData.isSubscription">
        If you have questions about your subscription, or would like more
        information about our additional features, please
        <a :href="contactUsUrl" target="_blank">contact us</a>.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueMarkdown from "vue-markdown-v2";
import { contactUsUrl, knowledgeBaseUrl } from "@/helpers/variables";
import RiskFactorsDefinitions from "@/components/ConverterDetailComponents/RiskFactorsDefinitions";
/**
 * Display subscription information for a particular site
 */
export default {
  name: "Flyout",
  components: {
    VueMarkdown,
    RiskFactorsDefinitions,
  },
  data() {
    return {
      /**
       * Attributes for the `vue-markdown` element
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      contactUsUrl,
      knowledgeBaseUrl,
    };
  },
  computed: {
    ...mapState({
      showBurger: (state) => state.app.showBurger,
      burgerData: (state) => state.app.burgerData,
      userData: (state) => state.user.userData,
    }),
    isInternalAdmin() {
      if (this.userData.is_internal && this.userData.is_admin) {
        return true;
      }
      return false;
    },
    description() {
      let description = null;
      if (this.burgerData && this.burgerData.description) {
        if (typeof this.burgerData.description === "object") {
          description =
            this.burgerData.description.description1 +
            "\n\n" +
            this.burgerData.description.description2 +
            "\n\n" +
            this.burgerData.description.description3;
        } else {
          description = this.burgerData.description;
        }
      }
      return description;
    },
  },
  methods: {
    ...mapActions({
      updateShowBurger: "app/updateShowBurger",
    }),
    /**
     * Close the flyout by updating the `showBurger` value to false
     */
    handleCloseHandler() {
      this.updateShowBurger(false);
      document.documentElement.classList.remove("no-scroll");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

.burger {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  &__overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(34, 36, 40, 0.5);
  }

  &__content {
    position: absolute;
    z-index: 2147483640;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    padding: 24px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--v-secondary-base);
    color: var(--v-black3-base);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    overflow-x: auto;

    &__header {
      &__close {
        color: var(--v-black3-base);
      }
    }
  }
}
.glossary-table ::v-deep {
  table {
    background: var(--v-secondary-base);
    color: var(--v-black3-base);
  }
}

.last-updated-text {
  color: grey !important;
}

.knowledge-base-link:hover {
  text-decoration: underline;
  color: var(--v-primary-base);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
</style>
