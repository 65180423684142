import { render, staticRenderFns } from "./CustomBreadcrumbs.vue?vue&type=template&id=b51b9b60&scoped=true&"
import script from "./CustomBreadcrumbs.vue?vue&type=script&lang=js&"
export * from "./CustomBreadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./CustomBreadcrumbs.vue?vue&type=style&index=0&id=b51b9b60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b51b9b60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem})
