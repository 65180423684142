<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <TheSidebar />
    <section
      class="content-wrapper"
      :class="{ 'sidebar-minimized': sidebarMinimize }"
    >
      <TheHeader />
      <ErrorAlert
        v-for="(error, index) in errors"
        :key="index"
        :error="error"
        :index="index"
      />
      <div class="body-container">
        <main class="main-container">
          <div class="pl-4 pr-4">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </div>
        </main>
      </div>
      <Flyout />
      <TheFooter />
    </section>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import Flyout from "@/components/Flyout";
import TheFooter from "./TheFooter";
import ErrorAlert from "@/components/ErrorAlert";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    Flyout,
    TheFooter,
    ErrorAlert,
  },
  data() {
    return {
      isAlert: true,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      showBurger: (state) => state.app.showBurger,
      burgerData: (state) => state.app.burgerData,
      sidebarMinimize: (state) => state.app.sidebarMinimize,
      errors: (state) => state.error.errors,
      clickCount: (state) => state.app.clickCount,
    }),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
      setClickCountByLocalStorage: "app/setClickCountByLocalStorage",
    }),
    globalTrackEventListener(event) {
      // Exit function if mdi-menu-down element or clicks exceed limit
      if (
        event.target.classList.contains("mdi-menu-down") ||
        this.clickCount > clickCountLimit
      ) {
        return;
      }
      // Extract 'data-ga' attribute
      const dataGaValue = event.target.getAttribute("data-ga");
      // Define default event label
      let eventLabel = "click_tbd";
      // Check if target has a class name
      if (typeof event.target.className === "string") {
        const className = event.target.className;
        // Track breadcrumb clicks
        if (className.includes("v-breadcrumbs")) {
          eventLabel = `breadcrumb_${event.target.innerText
            .trim()
            .replaceAll(" ", "_")}`;
        } else if (
          // Track admin menu clicks
          className.includes("v-list-item__title") &&
          dataGaValue &&
          !dataGaValue.includes("custom_menu_filter")
        ) {
          eventLabel = `avatar_dropdown_item_${event.target.innerText.trim()}`;
        }
      }
      // Check for loss model expansion panel, have to go deep to see parent class
      if (
        event.target?.offsetParent?.offsetParent?.offsetParent?.classList.contains(
          "loss-model",
        )
      ) {
        if (event.target?.offsetParent?.ariaExpanded) {
          eventLabel = "issue_detail_loss_model_expanded";
        } else {
          eventLabel = "issue_detail_loss_model_closed";
        }
      }
      // Check for comment created, diving into offset parent element
      if (event.target?.offsetParent?.attributes["data-ga"]) {
        eventLabel = event.target?.offsetParent?.attributes["data-ga"].value;
      }
      // Check for the data-ga attribute on clickable elements
      if (dataGaValue) {
        if (
          dataGaValue === "help_link_subscription" ||
          dataGaValue.includes("footer") ||
          dataGaValue.includes("refresh") ||
          dataGaValue.includes("issue_count") ||
          dataGaValue.includes("search") ||
          dataGaValue.includes("table_link_to_issues_table_filtered") ||
          dataGaValue === "custom_menu_filter_search" ||
          dataGaValue.includes("home_link_knowledge") ||
          dataGaValue === "home_link_glossary" ||
          dataGaValue.includes("table") ||
          dataGaValue === "site_dashboard_export_report" ||
          dataGaValue.includes("check_detail") ||
          dataGaValue.includes("issue_detail")
        ) {
          eventLabel = dataGaValue.replaceAll(" ", "_");
        } else if (
          dataGaValue.includes("tab") &&
          !dataGaValue.includes("table")
        ) {
          let trimmedInnerText = event.target.innerText.trim();
          if (trimmedInnerText.includes("Find,")) {
            trimmedInnerText = trimmedInnerText.replaceAll(",", "");
          }
          eventLabel = `${dataGaValue}_${trimmedInnerText.replaceAll(
            " ",
            "_",
          )}`;
        }
      }
      // If click is not identified, return without calling track event method
      if (eventLabel === "click_tbd") {
        return;
      }
      // Data sent to Google Analytics
      if (this.clickCount < clickCountLimit) {
        eventLabel = eventLabel.toLowerCase();
        this.incrementClickCount();
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: eventLabel,
          value: this.clickCount,
        });
      }
    },
  },
  mounted() {
    const numOfClicks = localStorage.getItem("clickCount");
    if (numOfClicks) {
      this.setClickCountByLocalStorage(numOfClicks);
    }
  },
  watch: {
    userData: {
      immediate: true,
      handler(data) {
        if (data?.id) {
          window.addEventListener("click", this.globalTrackEventListener);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.app {
  &__burger {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__overlay {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #222428;
      background: rgba(34, 36, 40, 0.5);
    }

    &__content {
      position: absolute;
      z-index: 2147483640;
      top: 0;
      right: 0;
      bottom: 0;
      width: 300px;
      padding: 24px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff !important;
      color: #242426;

      &__header {
        &__close {
          color: #242426;
        }
      }
    }
  }
}
.sidebar-minimized {
  margin-left: 87px;
}
</style>
