<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :nudge-left="nudgeLeft"
    :value="openMenu"
    v-model="menuOpened"
    class="menu"
  >
    <template v-slot:activator="{ on }">
      <div v-if="isSubMenu">
        <v-list-item
          v-if="adminOnly && isInternalAdmin"
          class="d-flex"
          v-on="on"
          ><v-icon style="margin-left: -12px"> mdi-chevron-left </v-icon>
          <v-list-item-action v-if="icon">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title class="ml-5">{{ name }}</v-list-item-title>
          <div class="flex-grow-1"></div>
        </v-list-item>
      </div>
      <div :color="color" @click="openMenu = true" text v-else v-on="on">
        <v-avatar
          @click="isOpen = true"
          :style="{
            backgroundColor: userData ? userData.user_color : '#F5C53D',
            cursor: 'pointer',
            height: '36px',
            width: '36px',
            minWidth: '36px',
            color: '#f7f7f8',
            fontWeight: 700,
          }"
        >
          {{ userData.user_initials }}
        </v-avatar>
      </div>
    </template>
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-divider :key="index" v-if="item.isDivider" />
        <sub-menu
          :is-offset-x="true"
          :is-offset-y="false"
          :is-open-on-hover="false"
          :is-sub-menu="true"
          nudge-left="315px"
          :key="index"
          :menu-items="item.menu"
          :name="typeof item.name === 'string' ? item.name : ''"
          :icon="item.icon"
          :admin-only="item.adminOnly"
          :is-internal-admin="isInternalAdmin"
          @sub-menu-click="emitClickEvent"
          :user-data="userData"
          v-else-if="item.menu"
        />
        <v-list-item :key="index" @click="emitClickEvent(item)" v-else>
          <v-list-item-action v-if="item.icon">
            <v-icon style="margin-left: 12px">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    name: { type: String, default: "" },
    icon: { type: String },
    adminOnly: { type: Boolean, default: false },
    nudgeLeft: { type: String },
    menuItems: Array,
    isOpenOnHover: { type: Boolean, default: false },
    color: { type: String, default: "secondary" },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isSubMenu: { type: Boolean, default: false },
    isInternalAdmin: { type: Boolean, default: false },
    userData: { type: Object, default: () => {} },
  },
  data: () => ({
    openMenu: false,
    menuOpened: false,
  }),
  methods: {
    emitClickEvent(item) {
      this.menuOpened = false;
      this.$emit("sub-menu-click", item);
    },
  },
};
</script>

<style lang="scss">
.v-list-item:hover {
  background-color: var(--v-hover-base);
}
</style>
