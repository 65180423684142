var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-list-item"},[(_vm.item.sites !== undefined || _vm.item.turbines !== undefined)?_c('v-list-group',{staticClass:"org-list-item",class:{ 'right-icon': _vm.isSubGroup },attrs:{"no-action":"","sub-group":_vm.isSubGroup},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{ref:_vm.item.id,staticClass:"v-list-item__border",class:{
          'v-list-item--active item-active':
            (_vm.$route.path.includes('org') && _vm.item.id == _vm.$route.params.orgId) ||
            (_vm.$route.path.includes('site') && _vm.item.id == _vm.$route.params.siteId),
        },style:({ borderLeft: _vm.borderColor })},[_c('v-list-item-title',{attrs:{"link":"","router":""},on:{"click":function($event){$event.stopPropagation();return _vm.goTo(_vm.item)}}},[_vm._v(_vm._s(_vm.item.name))])],1)]},proxy:true}],null,false,163318142),model:{value:(_vm.item.active),callback:function ($$v) {_vm.$set(_vm.item, "active", $$v)},expression:"item.active"}},[_vm._l((_vm.item.sites ? _vm.item.sites : _vm.item.turbines),function(subItem){return _c('div',{key:subItem.id},[_c('sidebar-list-item',{class:{ 'site-list-item': _vm.item.sites },attrs:{"item":subItem,"is-sub-group":true,"turbine-id":_vm.turbineId}})],1)})],2):_c('v-list-item',{staticClass:"turbine-list-item",attrs:{"id":"sidebar-turbine","link":"","router":""},on:{"click":function($event){$event.stopPropagation();return _vm.goTo(_vm.item)}}},[_c('v-list-item-content',{ref:_vm.item.id,staticClass:"v-list-item__turbine v-list-item__border",class:{
        'v-list-item--active item-active':
          (_vm.item.id == _vm.$route.params.turbineId &&
            !_vm.$route.path.includes('issue')) ||
          (_vm.item.id == _vm.turbineId && _vm.$route.path.includes('issue')),
      },style:({ borderLeft: _vm.borderColor })},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.item.fullName ? _vm.item.fullName : ("Turbine " + (_vm.item.shortname))))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }