<template>
  <SubMenu
    :menu-items="fileMenuItems"
    @sub-menu-click="onMenuItemClick"
    :user-data="userData"
    :is-internal-admin="isInternalAdmin"
  />
</template>

<script>
import { mapState, mapActions } from "vuex";
import SubMenu from "@/components/SubMenu";

export default {
  name: "TheHeaderDropdownAccnt",
  components: { SubMenu },
  data() {
    return {
      fileMenuItems: [
        {
          name: "Admin",
          adminOnly: true,
          icon: "mdi-account-circle",
          menu: [
            {
              name: "Users",
              adminOnly: true,
              icon: "mdi-account-group",
              action: () => {
                this.goToUserList();
              },
            },
            {
              name: "Sites",
              adminOnly: true,
              icon: "mdi-wind-turbine",
              action: () => {
                this.goToSites();
              },
            },
          ],
        },
        {
          name: "Settings",
          adminOnly: false,
          icon: "mdi-cog",
          action: () => {
            this.goToSettings();
          },
        },
        {
          name: "Logout",
          adminOnly: false,
          icon: "mdi-power-standby",
          action: () => {
            this.logout();
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
    }),
    isInternalAdmin() {
      return this.userData?.is_admin && this.userData?.is_internal;
    },
  },
  methods: {
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    ...mapActions({
      userLogout: "user/logout",
    }),
    goToUserList() {
      const routeName = this.$route.name;

      if (routeName === "Users") {
        return;
      } else {
        this.$router.push({ path: "/admin/users" });
      }
    },
    goToSites() {
      const routeName = this.$route.name;

      if (routeName === "Sites") {
        return;
      } else {
        this.$router.push({ path: "/admin/sites" });
      }
    },
    goToSettings() {
      const routeName = this.$route.name;

      if (routeName === "Settings") {
        return;
      } else {
        this.$router.push({ path: "/settings" });
      }
    },
    async logout() {
      await this.userLogout();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.option-title {
  font:
    1rem Roboto,
    "sans-serif";
}

.v-list-group__items .v-list-item {
  padding-left: 24px !important;
}
</style>
