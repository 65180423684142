<template>
  <div class="the-header d-flex align-center justify-space-between">
    <CustomBreadcrumbs class="mb-0" />
    <div class="d-flex align-center mr-7">
      <div class="d-flex flex-row align-baseline ma-0 pa-0 mt-3">
        <label class="mr-3">{{ newLookText }}</label>
        <v-switch
          inset
          dense
          v-model="isNewLookEnabled"
          @change="handleToggleChange"
          class="toggle ma-0 pa-0"
          style="transform: scale(0.75)"
        ></v-switch>
      </div>
      <div class="d-flex px-3">
        <NotificationMenu />
      </div>
      <TheHeaderDropdownAccnt class="px-3" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import NotificationMenu from "../components/NotificationMenu";
import {
  getNewLookPreference,
  setNewLookPreference,
  pageMappings,
  reversePageMappings,
} from "../services/preferenceService";
import mixpanel from "mixpanel-browser";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    CustomBreadcrumbs,
    NotificationMenu,
  },
  data() {
    const currencies = ["USD ($)"];
    return {
      time: [dayjs().subtract(1, "days").toDate(), dayjs().toDate()],
      selectedCurrency: currencies[0],
      currencies,
      isDark: false,
      isNewLookEnabled: getNewLookPreference(),
      newLookText: "",
    };
  },
  computed: {
    ...mapState({
      subscription: (state) => state.user.subscription,
      userData: (state) => state.user.userData,
    }),
  },
  methods: {
    handleToggleChange() {
      setNewLookPreference(this.isNewLookEnabled);
      const currentRouteName = this.$route.name;
      let targetRouteName;

      if (this.isNewLookEnabled) {
        this.newLookText = "Exit new look";
        targetRouteName = pageMappings[currentRouteName];
      } else {
        this.newLookText = "Preview new look";
        targetRouteName = reversePageMappings[currentRouteName];
      }

      // Track this event in mix panel
      mixpanel.track("new_look_toggle", {
        newLookEnabled: this.isNewLookEnabled,
      });

      if (targetRouteName) {
        this.$router
          .push({ name: targetRouteName, params: this.$route.params })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      }
    },
  },
  beforeMount() {
    this.isDark = this.$vuetify.theme.isDark;
    if (this.$route.name === "SiteDashboard") {
      this.newLookText = "Preview new Pulse";
    } else if (this.$route.name !== "SiteDashboard" && !this.isNewLookEnabled) {
      this.newLookText = "Preview new look";
    } else if (this.$route.name !== "SiteDashboard" && this.isNewLookEnabled) {
      this.newLookText = "Exit new look";
    }
  },
  watch: {
    immediate: true,
    $route: {
      handler(data) {
        if (data.name === "SiteDashboard") {
          this.newLookText = "Preview new Pulse";
          this.isNewLookEnabled = getNewLookPreference();
        } else if (data.name !== "SiteDashboard" && !this.isNewLookEnabled) {
          this.newLookText = "Preview new look";
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";

.date-filter {
  max-width: 15rem;
}

.currency-filter {
  max-width: 10rem;
}

::v-deep {
  .date-filter {
    .mx-input-wrapper {
      .mx-input {
        border: 0;
        height: 2.25rem;
        color: var(--v-black8-base);
      }
    }
  }

  .currency-filter {
    .v-input__control {
      min-height: 2.25rem !important;
    }

    .v-select__selection {
      color: var(--v-black8-base);
    }
  }
}
</style>
