var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"menu",attrs:{"close-on-content-click":false,"offset-x":_vm.isOffsetX,"offset-y":_vm.isOffsetY,"open-on-hover":_vm.isOpenOnHover,"nudge-left":_vm.nudgeLeft,"value":_vm.openMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isSubMenu)?_c('div',[(_vm.adminOnly && _vm.isInternalAdmin)?_c('v-list-item',_vm._g({staticClass:"d-flex"},on),[_c('v-icon',{staticStyle:{"margin-left":"-12px"}},[_vm._v(" mdi-chevron-left ")]),(_vm.icon)?_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c('v-list-item-title',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"flex-grow-1"})],1):_vm._e()],1):_c('div',_vm._g({attrs:{"color":_vm.color,"text":""},on:{"click":function($event){_vm.openMenu = true}}},on),[_c('v-avatar',{style:({
          backgroundColor: _vm.userData ? _vm.userData.user_color : '#F5C53D',
          cursor: 'pointer',
          height: '36px',
          width: '36px',
          minWidth: '36px',
          color: '#f7f7f8',
          fontWeight: 700,
        }),on:{"click":function($event){_vm.isOpen = true}}},[_vm._v(" "+_vm._s(_vm.userData.user_initials)+" ")])],1)]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c('v-list',[_vm._l((_vm.menuItems),function(item,index){return [(item.isDivider)?_c('v-divider',{key:index}):(item.menu)?_c('sub-menu',{key:index,attrs:{"is-offset-x":true,"is-offset-y":false,"is-open-on-hover":false,"is-sub-menu":true,"nudge-left":"315px","menu-items":item.menu,"name":typeof item.name === 'string' ? item.name : '',"icon":item.icon,"admin-only":item.adminOnly,"is-internal-admin":_vm.isInternalAdmin,"user-data":_vm.userData},on:{"sub-menu-click":_vm.emitClickEvent}}):_c('v-list-item',{key:index,on:{"click":function($event){return _vm.emitClickEvent(item)}}},[(item.icon)?_c('v-list-item-action',[_c('v-icon',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }