<template>
  <v-footer :fixed="false" class="justify-space-between">
    <div>
      <router-link to="/glossary" data-ga="footer_link_glossary"
        >Glossary</router-link
      >
      |
      <a
        :href="knowledgeBaseUrl"
        target="_blank"
        data-ga="footer_link_knowledge_base"
        >Knowledge base</a
      >
      |
      <a :href="contactUsUrl" target="_blank" data-ga="footer_link_contact_us"
        >Contact us</a
      >
    </div>
    <div v-if="isChina">{{ icpNotice }}</div>
    <div>
      WindESCo, Inc.<span class="ml-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div>
  </v-footer>
</template>

<script>
import {
  contactUsUrl,
  knowledgeBaseUrl,
  isChina,
  icpNotice,
} from "@/helpers/variables";

export default {
  name: "TheFooter",
  data() {
    return {
      contactUsUrl,
      knowledgeBaseUrl,
      isChina,
      icpNotice,
    };
  },
};
</script>
