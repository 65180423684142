<template>
  <WaitWrapper :isEmptyLoader="true">
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.lastItem ? null : item.to"
          class="text-subtitle-2 crumb-item"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </WaitWrapper>
</template>

<script>
import { mapState } from "vuex";
import WaitWrapper from "@/components/WaitWrapper";

/**
 * Display breadcrumbs in header of page for information and navigation
 */
export default {
  name: "CustomBreadcrumbs",
  components: {
    WaitWrapper,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      issueData: (state) => state.issueDetail.issueData,
      issueByDefId: (state) => state.issues.issueByDefId,
      userDetails: (state) => state.admin.userDetails,
      userEdited: (state) => state.admin.userEdited,
      orgData: (state) => state.org.orgData,
      siteData: (state) => state.site.siteData,
      singleCheckDetails: (state) => state.site.singleCheckDetails,
      turbineData: (state) => state.turbine.turbineData,
    }),
    /**
     * If route changes, this will return the route object
     */
    routeWatcher() {
      return {
        route: this.$route.path,
      };
    },
    breadcrumbs() {
      if (this.$route.path === `/orgs/${this.orgData?.id}`) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            lastItem: true,
            text: this.orgData.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.$route.params.orgId,
              },
            },
          },
        ];
      } else if (
        this.$route.path === `/orgs/new-org-dashboard/${this.orgData?.id}`
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            lastItem: true,
            text: this.orgData.name,
            to: {
              name: "NewOrgDashboard",
              params: {
                orgId: this.$route.params.orgId,
              },
            },
          },
        ];
      } else if (this.$route.path === `/sites/${this.siteData?.id}`) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.siteData.org.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.siteData.org.id,
              },
            },
          },
          {
            lastItem: true,
            text: this.siteData.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.$route.params.siteId,
              },
            },
          },
        ];
      } else if (
        this.$route.path === `/sites/new-site-dashboard/${this.siteData?.id}`
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.siteData.org.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.siteData.org.id,
              },
            },
          },
          {
            lastItem: true,
            text: this.siteData.name,
            to: {
              name: "NewSiteDashboard",
              params: {
                siteId: this.$route.params.siteId,
              },
            },
          },
        ];
      } else if (
        this.$route.path.includes(`/sites/${this.siteData?.id}/`) &&
        this.$route.params.componentName
      ) {
        let componentName = this.$route.params.componentName;
        let capitalized = null;
        if (componentName.includes("rotor-shaft")) {
          capitalized = "Rotor-shaft Assemblies";
        } else {
          // Replace all occurances of hyphen with space
          componentName = this.$route.params.componentName.replace(
            /[-_]/g,
            " ",
          );
          capitalized =
            componentName.charAt(0).toUpperCase() + componentName.slice(1);
        }
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.siteData.org.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.siteData.org.id,
              },
            },
          },
          {
            text: this.siteData.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.$route.params.siteId,
              },
            },
          },
          {
            lastItem: true,
            text: capitalized,
            to: {
              name: "AllSiteComponents",
              params: {
                siteId: this.$route.params.siteId,
                componentName: this.$route.params.componentName,
              },
            },
          },
        ];
      } else if (this.$route.path === `/turbines/${this.turbineData?.id}`) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.turbineData.farm.organization.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.turbineData.farm.organization.id,
              },
            },
          },
          {
            text: this.turbineData.farm.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.turbineData.farm.id,
              },
            },
          },
          {
            lastItem: true,
            text: "Turbine " + this.turbineData.shortname,
            to: {
              name: "TurbineDashboard",
              params: {
                turbineId: this.$route.params.turbineId,
              },
            },
          },
        ];
      } else if (
        this.$route.path ===
        `/turbines/new-turbine-dashboard/${this.turbineData?.id}`
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.turbineData.farm.organization.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.turbineData.farm.organization.id,
              },
            },
          },
          {
            text: this.turbineData.farm.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.turbineData.farm.id,
              },
            },
          },
          {
            lastItem: true,
            text: "Turbine " + this.turbineData.shortname,
            to: {
              name: "NewTurbineDashboard",
              params: {
                turbineId: this.$route.params.turbineId,
              },
            },
          },
        ];
      } else if (
        this.$route.path.includes(`/turbines/${this.turbineData?.id}/`) &&
        this.$route.params.componentName
      ) {
        let componentName = this.$route.params.componentName;
        if (componentName.includes("rotor-shaft")) {
          componentName = "Rotor-shaft Assembly";
        } else {
          // Replace all occurances of hyphen with space
          componentName = this.$route.params.componentName.replace(
            /[-_]/g,
            " ",
          );
        }
        // Capitalize the first letter of each word
        // Revisit when using names with hyphens that need to stay
        const capitalized = componentName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.turbineData.farm.organization.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.turbineData.farm.organization.id,
              },
            },
          },
          {
            text: this.turbineData.farm.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.turbineData.farm.id,
              },
            },
          },
          {
            text: "Turbine " + this.turbineData.shortname,
            to: {
              name: "TurbineDashboard",
              params: {
                turbineId: this.$route.params.turbineId,
              },
            },
          },
          {
            lastItem: true,
            text: capitalized,
            to: {
              name: "SubsystemComponentDetail",
              params: {
                turbineId: this.$route.params.turbineId,
                componentName: this.$route.params.componentName,
              },
            },
          },
        ];
      } else if (this.$route.path === `/turbine-issues/${this.issueData?.id}`) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.issueData.turbine.farm.organization.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.issueData.turbine.farm.organization.id,
              },
            },
          },
          {
            text: this.issueData.turbine.farm.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.issueData.turbine.farm.id,
              },
            },
          },
          {
            text: "Turbine " + this.issueData.turbine.shortname,
            to: {
              name: "TurbineDashboard",
              params: {
                turbineId: this.issueData.turbine.id,
              },
            },
          },
          {
            lastItem: true,
            text: "Issue detail",
            to: {
              name: "IssueDetail",
              params: {
                issueId: this.$route.params.issueId,
              },
            },
          },
        ];
      } else if (
        this.$route.path ===
        `/turbine-issues/new-issue-page/${this.issueData?.id}`
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.issueData.turbine.farm.organization.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.issueData.turbine.farm.organization.id,
              },
            },
          },
          {
            text: this.issueData.turbine.farm.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.issueData.turbine.farm.id,
              },
            },
          },
          {
            text: "Turbine " + this.issueData.turbine.shortname,
            to: {
              name: "TurbineDashboard",
              params: {
                turbineId: this.issueData.turbine.id,
              },
            },
          },
          {
            lastItem: true,
            text: "New issue detail",
            to: {
              name: "NewIssueDetail",
              params: {
                issueId: this.$route.params.issueId,
              },
            },
          },
        ];
      } else if (this.$route.path === "/admin/sites/new") {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: "Sites",
            to: {
              name: "Sites",
            },
          },
          {
            lastItem: true,
            text: "New site",
            to: {
              name: "NewSite",
            },
          },
        ];
      } else if (
        this.$route.name === "EditSite" &&
        this.$route.params.siteId == this.siteData.id
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: "Sites",
            to: {
              name: "Sites",
            },
          },
          {
            lastItem: true,
            text: this.siteData.name,
            to: {
              name: "EditSite",
            },
          },
        ];
      } else if (this.$route.path === "/admin/users/new") {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: "Users",
            to: {
              name: "Users",
            },
          },
          {
            lastItem: true,
            text: "New user",
            to: {
              name: "NewUser",
            },
          },
        ];
      } else if (
        this.$route.path.includes("users") &&
        this.$route.params.userId &&
        Object.keys(this.userDetails).length > 0
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: "Users",
            to: {
              name: "Users",
            },
          },
          {
            lastItem: true,
            text: this.userDetails.full_name,
            to: {
              name: "UserDetail",
              params: {
                userId: this.$route.params.userId,
              },
            },
          },
        ];
      } else if (
        this.$route.path.includes("/checks") &&
        this.$route.params.issueDefId &&
        this.$route.params.siteId &&
        this.singleCheckDetails?.id == this.$route.params.issueDefId &&
        this.singleCheckDetails?.site.id == this.$route.params.siteId
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            text: this.singleCheckDetails.site.org.name,
            to: {
              name: "OrgDashboard",
              params: {
                orgId: this.singleCheckDetails.site.org.id,
              },
            },
          },
          {
            text: this.singleCheckDetails.site.name,
            to: {
              name: "SiteDashboard",
              params: {
                siteId: this.$route.params.siteId,
              },
            },
          },
          {
            lastItem: true,
            text: "Check detail",
            to: {
              name: "CheckDetail",
              params: {
                siteId: this.$route.params.siteId,
                issueDefId: this.$route.params.issueDefId,
              },
            },
          },
        ];
      } else if (
        this.$route.name !== "HomePage" &&
        this.$route.name !== "OrgDashboard" &&
        this.$route.name !== "SiteDashboard" &&
        this.$route.name !== "TurbineDashboard" &&
        this.$route.name !== "IssueDetail" &&
        this.$route.name !== "UserDetail" &&
        this.$route.name !== "Check Detail" &&
        this.$route.name !== "EditSite" &&
        this.$route.name !== "GlobalDashboard" &&
        this.$route.name !== "SubsystemComponentDetail" &&
        !this.$route.name.includes("New")
      ) {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
          {
            lastItem: true,
            text: this.$route.name,
            to: {
              name: this.$route.name,
            },
          },
        ];
      } else {
        return [
          {
            text: "Home",
            to: {
              name: "HomePage",
            },
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  padding-left: 30px;
}
</style>
