<template>
  <v-navigation-drawer
    permanent
    :mini-variant.sync="sidebarMinimize"
    class="sidebar"
  >
    <div v-if="sidebarMinimize">
      <img src="/img/site-logo.svg" class="sidebar-brand-full logo" />
      <img
        :src="
          $vuetify.theme.isDark
            ? '/img/logo-icon-dark.svg'
            : '/img/logo-icon.svg'
        "
        class="sidebar-brand-minimized logo mb-6"
        @click.stop="goToDefault()"
      />
    </div>
    <v-img
      v-else
      :src="
        $vuetify.theme.isDark ? '/img/site-logo-dark.svg' : '/img/site-logo.svg'
      "
      id="sidebar-home-logo"
      class="logo mb-6"
      @click.stop="goToDefault()"
    />
    <div v-if="sidebarMinimize" class="d-flex justify-center">
      <v-icon @click.stop="toggleMinimize(false)">mdi-magnify</v-icon>
    </div>
    <v-text-field
      v-else
      @input="debounceFilter"
      label="Search..."
      solo
      dense
      hide-details
      clearable
      append-icon="mdi-magnify"
    ></v-text-field>
    <v-list :class="{ 'd-flex justify-center pl-3': sidebarMinimize }">
      <v-list-group :value="true" :append-icon="null">
        <template v-slot:activator>
          <v-list-item-content>
            <div
              data-ga="sidebar-home-icon"
              class="d-flex align-center"
              @click.stop="goToDefault()"
            >
              <v-icon
                color="black1"
                :class="{ 'mr-4': !sidebarMinimize }"
                class="home-icon"
                >mdi-home</v-icon
              >
              <v-list-item-title v-if="!sidebarMinimize">Home</v-list-item-title
              ><v-icon v-if="!sidebarMinimize" @click.stop="collapseSidebar()"
                >mdi-collapse-all-outline
              </v-icon>
            </div>
          </v-list-item-content>
        </template>
        <DataLoading v-if="loading" />
        <sidebar-list-item
          v-else
          v-for="org in filteredResults"
          :item="org"
          :key="org.title"
          :is-sub-group="true"
          :turbine-id="issueTurbineId || localStorageIssueTurbineId"
        />
      </v-list-group>
      <div v-if="filteredResults.length === 0 && !loading">No results</div>
    </v-list>
    <ul class="c-side-nav">
      <li @click.stop="toggleMinimize(!sidebarMinimize)">
        <a class="d-flex">
          <div class="d-flex justify-space-around sidebar-minimize">
            <v-icon v-if="!sidebarMinimize"
              >mdi-arrow-left-bold-box-outline</v-icon
            >
            <v-icon v-if="sidebarMinimize"
              >mdi-arrow-right-bold-box-outline</v-icon
            >
            <span v-if="!sidebarMinimize" class="ml-2">Close</span>
          </div>
        </a>
      </li>
    </ul>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SidebarListItem from "@/components/SidebarListItem";
import { deepFilter } from "@/helpers/functions";
import DataLoading from "@/components/DataLoading";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "TheSidebar",
  components: {
    SidebarListItem,
    DataLoading,
  },
  data() {
    return {
      filter: "",
      clonedOrgs: [],
      debounce: null,
      userToggledSidebar: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      sidebarMinimize: (state) => state.app.sidebarMinimize,
      orgs: (state) => state.app.orgs,
      loading: (state) => state.app.loading.getOrgs,
      issueTurbineId: (state) => state.issueDetail.issueTurbineId,
      clickCount: (state) => state.app.clickCount,
    }),
    localStorageIssueTurbineId() {
      return +localStorage.getItem("issueTurbineId");
    },
    filteredResults() {
      if (this.filter !== "" && this.filter !== null) {
        // eslint-disable-next-line no-undef
        const clone = structuredClone(this.clonedOrgs);
        return deepFilter(clone, this.filter);
      }

      return this.clonedOrgs;
    },
    routeWatcher() {
      return {
        route: this.$route,
      };
    },
  },
  methods: {
    ...mapActions({
      toggleSidebarMinimize: "app/toggleSidebarMinimize",
      getOrgs: "app/getOrgs",
      getIssueStatusDefinitions: "issues/getIssueStatusDefinitions",
      incrementClickCount: "app/incrementClickCount",
    }),
    handleResize() {
      const width = window.innerWidth;
      const shouldMinimize = width < 1200;
      if (this.sidebarMinimize !== shouldMinimize && !this.userToggledSidebar) {
        this.toggleMinimize(shouldMinimize);
      }
      this.userToggledSidebar = false;
    },
    toggleMinimize(minimize) {
      this.userToggledSidebar = true;
      this.toggleSidebarMinimize(minimize);
    },
    debounceFilter(event) {
      this.filter = "";

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.filter = event;
      }, 300);
    },
    goToDefault() {
      // Data sent to Google Analytics
      if (this.clickCount < clickCountLimit) {
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: "sidebar_home",
          value: this.clickCount,
        });
        this.incrementClickCount();
      }
      this.$router
        .push({
          name: "HomePage",
        })
        .catch(() => {});
    },
    collapseSidebar() {
      this.clonedOrgs.forEach((org) => {
        org.active = false;
        org.sites.map((site) => (site.active = false));
      });
    },
    setActiveSidebarItem() {
      let breakCheck = false;
      for (const org of this.clonedOrgs) {
        org.active = false;
        for (const site of org.sites) {
          if (
            this.$route.path.includes("site") &&
            this.$route.params.siteId == site.id
          ) {
            org.active = true;
            site.active = false;
            breakCheck = true;
            break;
          }
          site.active = false;
          for (const turbine of site.turbines) {
            if (
              this.$route.path.includes("turbines") &&
              this.$route.params.turbineId == turbine.id
            ) {
              org.active = true;
              site.active = true;
              breakCheck = true;
              break;
            }
            if (
              this.$route.path.includes("turbine-issues") &&
              turbine.id == this.issueTurbineId
            ) {
              org.active = true;
              site.active = true;
              turbine.active = true;
              breakCheck = true;
              break;
            }
            if (breakCheck) break;
          }
        }
        if (breakCheck) break;
      }
    },
  },
  async beforeMount() {
    if (this.orgs.length === 0) {
      this.getIssueStatusDefinitions();
      await this.getOrgs();
    }
    // eslint-disable-next-line no-undef
    this.clonedOrgs = structuredClone(this.orgs);
    this.$nextTick(() => {
      this.setActiveSidebarItem();
    });
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    routeWatcher: {
      immediate: true,
      deep: true,
      handler(data) {
        const { route } = data;
        if (data) {
          if (!route.name && this.clonedOrgs.length > 0) {
            this.goToDefault();
          }
        }
        if (this.clonedOrgs.length > 0) {
          this.setActiveSidebarItem();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.logo {
  width: 100%;
  height: 50px;
  cursor: pointer;
}
.sidebar-brand-minimized.logo {
  scale: 1.7;
}
.home-icon {
  height: 18px;
}
.sidebar-brand-full {
  display: none;
}
.v-navigation-drawer {
  background-color: var(--v-black10-base) !important;
  padding: 11px 16px 0;
  z-index: 2;
  position: fixed;
}
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}
.v-navigation-drawer--mini-variant {
  width: 87px !important;
}
.sidebar {
  .v-list {
    padding-right: 6px;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    height: calc(100vh - 200px);
  }
  .v-list-item__title {
    display: flex;
    align-items: center;
  }
  .v-list:hover {
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #aaa;
  }
}

.sidebar-minimize {
  min-width: 50px;
}
</style>
